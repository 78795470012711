@import "../../global.scss";

.navbar {
  width: 100%;
  height: 70px;
  background-color: $neutralDark;
  color: $neutralLight;
  position: fixed;
  top: 0;
  overflow: hidden;
  z-index: 3;
  transition: all .5s ease;

  .wrapper {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      img{
        width: 200px;
        padding-top: 5px;
      }
    }

    .right {
      .hamburger {
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        overflow: hidden;
        padding-left: 15px;

        span {
          width: 100%;
          height: 4px;
          background-color: $neutralLight;
          transform-origin: left;
          transition:  all .5s ease-out;
        }
      }
    }
  } 

  &.active {
    background-color: $neutralLight;
    
    .logo{
        transition: all 1s ease;
    }
  
     
    .hamburger {
      span {
        &:first-child {
          background-color: $neutralDark;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          background-color: $neutralDark;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
