@import "../../global.scss";

.intro {
  background-color: $neutralLight;
  display: flex;
  justify-content: space-around;
  background-image: url("../../../public/assets/philly-art-museum.jpg");
  background-color: #cccccc;
  height: 500px;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .left {
    display: flex;
    flex: 0.6;
    overflow: hidden;
    filter: drop-shadow(8px 10px $secondaryColor);
    justify-content: center;
    align-items: center;

    @include mobile{
      flex: 1;
    }

    .imgContainer {
      width: 40%;
      margin: 30px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      overflow: hidden;

      @include mobile {
        width: 35%;
        margin: 0px;
        
      }
    }
  }

  .right {
    flex: 0.4;
    overflow: hidden;
    background-color: $neutralLight;
    width: 100%;

    @include mobile{
      flex: 1;
    }

    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .buttons{
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: space-around;
   

        a{
          width: 200px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          background-color: $secondaryColor;
          color: $neutralDark;
          box-shadow: 5px 5px $neutralDark;
          font-size: 20px;
          margin-top: 45px;
          margin-bottom: 45px;
          padding: 10px;
          font-weight: 900;
          border: 0;

          @include mobile{
            width: 45%;
            margin-right: 8px;
          }
        }
      }

    }

    h1 {
      font-size: 55px;

      @include mobile {
        font-size: 35px;
      }
    }

    h2 {
      font-size: 30px;

      @include mobile {
        font-size: 24px;
      }
    }
    h3 {
      font-size: 24px;

      span {
        color: $accentColor;
      }
    }
  }
}
