@import "../../global.scss";

.menu{
    width: 300px;
    height: 100vh;
    background-color: $neutralLight;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all .5s ease;

    &.active{
        right: 0;
        background-color: $neutralDark;
    }

    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 28px;
        color: $neutralLight;
        li{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 5px;

            a{
                margin-left: 15px;
                font-size: inherit;
                color: inherit;
                text-decoration: none;

            }

            button{
                width: 250px;
                background-color: $secondaryColor;
                color: $neutralDark;
                box-shadow: 5px 5px $neutralLight;
                font-size: 20px;
                margin: 15px 15px 10px 0px;
                padding: 5px;
                font-weight: 900;
                border: 0;
            }
        }
    }    
}