$neutralDark: #10171d;
$neutralMid: #c7e4fc;
$neutralLight: #fdfdfd;
$secondaryColor: #E09F3E;
$accentColor: #E03E49;

$breakWidth: 900px;

@mixin mobile{
    @media (max-width: #{$breakWidth}){
        @content
    }
}