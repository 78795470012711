@import "../../global.scss";

.about{
    background-color: $neutralMid;
    display: flex;

    @include mobile{
        flex-direction: column;

    }

    .right{
        flex: .6;
        background-color: $neutralDark;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $neutralLight;
        padding: 50px;

        @include mobile {
            flex: 1;
            padding: 25px;
        }
        
        h1{
            font-size: 45px;
            align-self: flex-start;
            overflow: hidden;
            padding: 15px;
            padding-top: 0;

            @include mobile{
                font-size: 36px;
            }
        }

        p{
            font-size: 20px;
            padding-bottom: 35px;
            overflow: hidden;

            @include mobile {
                font-size: 18px;
            }
        }

        .tech-stack {
            width: 60%;
            font-size: 65px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 10px 0px;
            filter: drop-shadow(3px 3px $secondaryColor);
            overflow: hidden;
  
            @include mobile{
              width: 90%;
              font-size: 45px;
              padding: 8px;
            }
            
          }

        hr{
            width: 80%;
            border: 2px solid $secondaryColor;
            margin-bottom: 8px;
            margin-left: 5px;
            overflow: hidden;
            align-self: start;

            @include mobile {
                width: 90%;
            }
        }


        tr{
            width: 90%;
            margin: 8px;
            font-size: 20px;
            box-shadow: 4px 4px $secondaryColor;
            overflow: hidden;
            background-color: $neutralMid;
            color: $neutralDark;
            text-align: center;
            padding-bottom: 8px;


            @include mobile{
                font-size: 15px;
            }

            .table-head{
                width: 30vw;
                max-width: 150px;
                text-align: right;
                font-weight: 600;
                overflow: hidden;
                font-size: 24px; 
                padding: 8px;

                @include mobile{
                    font-size: 18px;
                }

            }

            .table-detail {
                padding: 8px;

            }

        }

    }

    .left{
       flex: .4;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center; 

       @include mobile {
           display: none;
       }

       h2{
           padding: 0 10px;
           font-size: 2em;
       }

       a{
           display: flex;
           align-items: center;
           justify-content: center;
           padding: 8px;
       }
       img {
           border-radius: 5%;
           box-shadow: 5px 5px $secondaryColor;
           width: 80%;
           margin-bottom: 5%;
       }
    
    }
}