@import "../../global.scss";

.contact{
    background-color: $secondaryColor;
    display: flex;

    @include mobile{
      flex-direction: column;
    }

    .left{
        flex: 1;
        background-color: $neutralLight;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1{
          font-size: 50px;

          @include mobile{
            font-size: 40px;
          }
        }
    
        .icon{
            font-size: 90px;
            color: $neutralDark;
            filter: drop-shadow(3px 3px $secondaryColor);
        }

        .socials{
          width: 50%;
          font-size: 65px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-bottom: 10px;
          filter: drop-shadow(3px 3px $secondaryColor);

          a, a:visited, a:hover, a:active{
            color:inherit;
            text-decoration: none;
          }

          @include mobile{
            width: 70%;
          }
          
        }
    }

    .right{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        h1{
            margin-top: 40px;
            font-size: 50px;
            overflow: hidden;

            @include mobile{
              margin-top: 20px;
              font-size: 40px;
            }
        }

        hr{
            width: 80%;
            border: 2px solid $neutralDark;
            margin-bottom: 8px;
            overflow: hidden;
        }
        form {
            width: 70%;
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0;
            filter: drop-shadow(4px 5px $neutralDark);
      
            input {
              width: 100%;
              height: 30px;
              font-size: 14px;
              margin-bottom: 8px;
              padding-left: 25px;
                    
              @include mobile {
                height: 20px;
              }
            }
      
            textarea {
              width: 100%;
              height: 250px;
              font-size: 14px;
              margin-bottom: 8px;
              padding-left: 25px;
      
              @include mobile {
                padding: 10px;
                height: 100px;
              }
            }
      
            button {
              width: 300px;
              height: 30px;
              color: white;
              background-color: $accentColor;
              border: none;
              font-weight: 500;
              cursor: pointer;
      
              &:focus {
                outline: none;
              }
            }
      
          }
          span {
            color: $neutralLight;
            font-size: 20px;
            margin-bottom: 25px;

            @include mobile{
              font-size: 15px;
              margin-bottom: 10px;
            }
          }
}
}